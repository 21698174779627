<template>
  <div class="mod-config upload-test">
    <el-row class="upload">
      <fileUpload v-model="formData.files" > </fileUpload>

      {{ $t('fileupload.uploadText') }}:<br />
      <div v-for="item in formData.files" :key="item">
        {{ item }}
      </div>
    </el-row>

     <el-button @click="publishFlow()">{{ $t('fileupload.testStep') }}</el-button>

   
    formData.flowType::  {{formData.flowType}}<br>
    sex: {{formData.sex}}

    <el-divider content-position="center">excel {{ $t('fileupload.export') }} </el-divider>

    <div>
      <el-form :inline="true"   :rules="dataRule"  :model="formData"  ref="dataForm" label-width="80px">

        <el-form-item label="flowType" prop="flowType">
          <sys-dict-select v-model="formData.flowType"  dictType="flow_type" />
        </el-form-item>
        <el-form-item label="sex" prop="sex">
          <!-- <sys-dict-select v-model="formData.sex"  dictType="sex" /> -->
           <sys-dict-radio v-model="formData.sex"  dictType="sex" />
        </el-form-item>
        <el-form-item>

        <el-button type="primary" @click="dataFormSubmit()" >{{ $t('confirm') }}</el-button>
          <!--  <el-button @click="exportExcel()">前端导出页面表格</el-button>
        <el-button @click="exportExcel2()">前端导出自定义数据表格</el-button> -->

          <el-button @click="exportExcel3()">{{ $t('fileupload.backEndExportTemp') }}</el-button>
          <el-button @click="exportExcel4()">{{ $t('fileupload.backEndExportTab') }}</el-button>
        </el-form-item>
      </el-form>

      <Table :columns="columns" :datas="datas" :page="false" :showIndex="true">
      </Table>
    </div>
  </div>
</template>
<script>
import fileUpload from "@/components/fileupload";

import Table from "@/components/table";

/*import FileSaver from 'file-saver' 
import XLSX from 'xlsx'
import {formatJson,formatTableJson} from '@/utils/index'*/

export default {
  data() {
    return {
      formData: {
        files: [],
        sex: '',
        flowType: ''
      },
      dataRule: {
        flowType: [
          { required: true, message: this.$t('fileupload.flowTypeNotEmpty'), trigger: ["blur","change"] }
        ],
        sex: [
          { required: true,whitespace: true, message: this.$t('fileupload.sexNotEmpty'), trigger: "change" }
        ],
      },
      columns: [
        { prop: "date", label: this.$t('fileupload.date') },
        { prop: "name", label: this.$t('fileupload.name') },
        { prop: "address", label: this.$t('fileupload.address') }
      ],
      datas: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ]
    };
  },
  components: {
    fileUpload,
    Table
  },
  created() {},
  activated() {
    const id = this.$route.query.id;

    this.$message.info("id:" + id);
  },
  methods: {
    exportExcel() {
      /* generate workbook object from table */
      /*var xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
      var wb = XLSX.utils.table_to_book(document.querySelector('#studentTable'), xlsxParam)
 
      var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '学生情况表.xlsx')
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout)
        }
      }
      return wbout*/
    },
    exportExcel2() {
      /*　require.ensure([], () => {
         
        const { export_jsons_to_excel } = require('@/vendor/Export2Excel');
      　　　　 
      　　const data = formatTableJson(this.columns,this.datas)  
         

          const jsonData = [data ,data]
          const sheetNames = ['学生信息1' ,'学生信息2']
   

          const filename =  '学生信息'
          export_jsons_to_excel(sheetNames, jsonData, filename);

      　　})*/
    },
    dataFormSubmit(){
        this.$refs["dataForm"].validate()
    },
    publishFlow(){
      this.$http({
            url: this.$http.adornUrl("/project/testStart"),
            method: "get" 
          }).then(({data})=> {
            if(data && data.code === 0) {
              this.$message.info('success')
            } else {
              this.$message.error(data.msg)
            }
          })
    },
    exportExcel3() {
      window.location.href = this.$http.adornUrl("/stat/export1");
    },
    exportExcel4() {
      window.location.href = this.$http.adornUrl("/stat/export2");
    }
  }
};
</script>
<style lang="scss">
.upload-test {
  .upload {
    line-height: 50px;
  }
}
</style>
