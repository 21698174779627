<template>
  <div v-loading="loading">
    <el-checkbox-group v-model="checkList" @change="changeSelect">
      <el-radio-group
        v-model="select"
        style="display: block;"
        @change="changeSelect"
      >
        <el-table
          :data="dataList"
          :max-height="maxHeight"
          v-if="!loading"
          border
          stripe
          :show-summary="showSummary"
          @row-dblclick="clickDouble"
          :row-style="rowstyleFun"
          :cell-style="cellStyleFun"
          @sort-change="sortChange"
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            width="50"
            header-align="center"
            align="center"
            v-if="showIndex"
          >
          </el-table-column>
          <el-table-column v-if="showSelection" width="35">
            <template slot-scope="scope">
              <div v-if="multiple && multiple == true">
                <!-- 多选 -->
                <el-checkbox :label="scope.$index">{{ "" }}</el-checkbox>
              </div>
              <div v-else>
                <!-- 单选 -->
                <el-radio :label="scope.$index">{{ "" }}</el-radio>
              </div>
            </template>
          </el-table-column>
          <slot name="customColumns"></slot>

          <el-table-column
            :prop="item.prop"
            :key="item.prop"
            :fix="item.fix"
            :width="item.width"
            :show-overflow-tooltip="true"
            header-align="center"
            align="center"
            sortable
            :label="item.label"
            v-for="item in columns"
          >
          </el-table-column>
        </el-table>
      </el-radio-group>
    </el-checkbox-group>
    <el-container v-if="page" class="pager-div custom-pager">
      <el-pagination
        @current-change="currentChangeHandle"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="totalCount"
      >
      </el-pagination>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "tr-table",
  props: {
    columns: {
      type: Array,
      default: function() {
        return [];
      }
    },
    datas: {
      type: Array,
      default: function() {
        return [];
      }
    },
    max_height: {
      type: Number,
      default: null
    },
    dblclick: {
      type: Function,
      default: null
    },
    rowstyle: {
      type: Function,
      default: null
    },
    cellStyle: {
      type: Function,
      default: null
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    showSelection: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    page: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: Number,
      default: 500
    },
    showSummary: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      checkList: [],
      select: "",

      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0
    };
  },
  watch: {
    datas: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        // 默认所有val 全部补一个id 标明顺序
        let i = 0;
        if (val && val.length > 0) {
          val.forEach(t => {
            t._id_ = i++;
          });
        }

        this.changeData();
      },
      deep: true
    },
    columns: {
      handler(val, oldVal) {
        this.changeData();
      },
      deep: true
    }
  },
  mounted: function() {
    let i = 0;
    if (this.datas && this.datas.length > 0) {
      this.datas.forEach(t => {
        t._id_ = i++;
      });
    }
    this.changeData();
  },
  methods: {
    // 选中数据切换事件
    changeSelect(v) {
      const s = this.getSelection();
      this.$emit("changeSelect", s);
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.changeData();
    },
    rowstyleFun(row) {
      if (this.rowstyle) {
        return this.rowstyle(row);
      }
      return null;
    },
    cellStyleFun(cell) {
      if (this.cellStyle) {
        return this.cellStyle(cell);
      }
      return null;
    },

    clickDouble(row, event) {
      if (this.dblclick) {
        this.dblclick(row);
      }
    },
    sortChange(c) {
      const prop = c.prop;
      const order = c.order;

      let compare_ = null;
      if (prop && order) {
        compare_ = function(val1, val2) {
          const v1 = val1[prop] ? val1[prop] : "";
          const v2 = val2[prop] ? val2[prop] : "";
          if (order.indexOf("asc") >= 0) {
            return v1.localeCompare(v2);
          } else {
            return v2.localeCompare(v1);
          }
        };
      } else {
        compare_ = function(val1, val2) {
          return val1["_id_"] - val2["_id_"];
        };
      }

      if (compare_) this.datas.sort(compare_);

      //this.pageIndex = 1

      this.changeData();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.changeData();
    },
    getSelection() {
      if (!this.showSelection) {
        return [];
      }

      const selectIndexes = this.multiple ? this.checkList : [this.select];

      // 获取row
      let selectDatas = [];
      for (let i = 0; i < selectIndexes.length; i++) {
        const index = selectIndexes[i];
        selectDatas.push(this.datas[index]);
      }

      return selectDatas;
    },
    changeData() {
      console.log("change table data", this.datas);
      if (!this.datas) return;
      this.loading = true;
      if (this.page) {
        this.totalCount = this.datas.length;
        let startIndex = (this.pageIndex - 1) * this.pageSize;
        let endIndex = startIndex + this.pageSize;
        if (endIndex > this.totalCount) {
          endIndex = this.totalCount;
        }

        this.dataList =
          this.datas && this.datas.length > 0
            ? this.datas.slice(startIndex, endIndex)
            : [];

        this.pageIndex = 1;
      } else {
        this.dataList = this.datas;
      }

      this.$nextTick(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style>
.custom-pager {
  text-align: center;
}
</style>
